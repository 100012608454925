<template>
  <v-footer padless>
    <div class="flex footer">
      <section class="footer__img">
        <a href="/">
          <img src="../img/CampItLogo-long.png" alt="Logo" />
        </a>
        <div class="footer--social-media">
          <a href="link/to/facebookpage">
            <img src="../img/facebook.png" alt="Logo" />
          </a>
          <a href="link/to/instagrampage">
            <img src="../img/instagram.png" alt="Logo" />
          </a>
        </div>
      </section>
      <section class="footer__section">
        <p class="dark">Contact Us</p>
        <p class="text-white"><a href="/about">About Us</a></p>
        <p class="text-white"><a href="mailto:info@finder.camp">Email: info@finder.camp</a></p>
        <p class="text-white">Sign up for the newsletter</p>
      </section>
      <section class="footer__section footer__section--help">
        <p class="dark">Help</p>
        <p class="text-white">Terms</p>
        <p class="text-white">Privacy</p>
        <p class="text-white">Ask a question</p>
      </section>
      <section class="footer__section">
        <p class="dark">Camps</p>
        <p class="text-white">Browse by camp</p>
        <p class="text-white">Browse by category</p>
        <p class="text-white">Browse by age group</p>
        <p class="text-white">Browse by dates</p>
      </section>
      <section class="footer__section">
        <button class="footer__section--button" onClick={Login}>Create an account or sign in</button>
        <button class="footer__section--button" onClick={Search}>Find sessions</button>
      </section>
    </div>
  </v-footer>
</template>
<script>
export default {
name: 'Footer',
}
</script>
<style scoped>
.footer {
 background-color: #689F39;
 padding-top: 20px
}
.flex {
display: flex;
}
.footer__section {
flex: 3;
}
.footer__img {
flex: 5;
}
.footer--social-media {
width: 2.5rem;
display: flex;
justify-content: flex-start;
border-radius: 6px;
margin-left: 9rem;
}
.footer--social-media img {
background-color: #fff;
border-radius: 6px;
height: auto;
max-height: 32px;
max-width: 32px;
margin: 0 45px 0 0;
padding: 0.2rem;
}
.footer__img img {
width: 100%;
height: auto;
}
.footer__section button {
width: 200px;
height: 50px;
border-radius: 10px;
border: 0px;
color: #689F39;
font-weight: 800;
margin-top: 10px;
}
.footer__section--help {
margin-right: auto;
width: 13rem;
}
.footer__section--button {
background-color: #fff;
}
.dark {
color: #242620;
font-size: 18px;
font-weight: 700;
}
.text-white, .text-white a {
color: #fff;
}
.footer__section:last-child {
margin-top: 25px;
flex:3;
padding-right: 1rem;
}
</style>
