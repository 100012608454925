<template>
  <div class="ads">
    <Navbar></Navbar>
    <img src="@/img/AdvertisePage1.jpg" alt="Coming Soon Search Tool" />
    <img src="@/img/AdvertisePage2.jpg" alt="Coming Soon Search Tool" />
    <img src="@/img/AdvertisePage3.jpg" alt="Coming Soon Search Tool" />
    <img src="@/img/AdvertisePage4.jpg" alt="Coming Soon Search Tool" />
    <div class="adForm">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row v-if="showError">
          <v-col :cols="12">
            <p class="error">There was an error submitting your form. Please try again later.</p>
          </v-col>
        </v-row>
        <v-row v-if="validationError">
          <v-col :cols="12">
            <p class="error">There is a problem with validations in your form. Please fix the error(s) and try again.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="6">
            <v-text-field
              v-model="fname"
              label="Contact First Name"
              :rules="[rules.required('fname')]"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="lname"
              label="Contact Last Name"
              :rules="[rules.required('lname')]"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="bname"
              label="Business Name"
              :rules="[rules.required('bname')]"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="website"
              label="Website URL"
              :rules="[rules.website]"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="email"
              label="Email"
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="phone"
              label="Phone"
              :rules="[rules.phone]"
              @input="formatPhone"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="time"
              label="Best Time to be Reached (optional)"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              v-model="contactMethod"
              label="Preferred Method of Contact (optional)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea
              v-model="details"
              label="Other details you would like to share (optional)"
              rows="4"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-btn class="mr-4" @click="submit">Submit</v-btn>
          <v-btn @click="clear">Clear</v-btn>
        </v-row>
      </v-form>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import AdsService from '@/api-services/ads.service.js';

export default {
  name: 'Ads',
  components: {
    Navbar: Navbar,
    Footer: Footer
  },
  data() {
    return {
      valid: false, // Keeps track of form validation state
      cleared: false, // Tracks if the form has been cleared
      fname: '',
      lname: '',
      bname: '',
      email: '',
      website: '',
      phone: '',
      time: '',
      contactMethod: '',
      details: '',
      showError: false,
      validationError: false,
      rules: {
        required: (field) => v => {
          if (this.cleared) return true; // Skip validation if form was cleared
          return !!v || `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        },
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        website: v => /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*$/.test(v) || 'Website must be valid',
        phone: v => /^\d{3}-\d{3}-\d{4}$/.test(v) || 'Phone number must be valid (xxx-xxx-xxxx)',
      },
    }
  },
  methods: {
    formatPhone() {
      let cleaned = ('' + this.phone).replace(/\D/g, '');
      let formatted = cleaned.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
      this.phone = formatted;
    },
    async submit() {
      this.cleared = false; // Reset the cleared flag before validating
      if (this.$refs.form.validate()) {
        let form = {
          firstName: this.fname,
          lastName: this.lname,
          businessName: this.bname,
          website: this.website,
          email: this.email,
          phone: this.phone,
          contactMethod: this.contactMethod,
          bestTimeAndDayToContact: this.time,
          details: this.details
        };
        
        try {
          this.showError = false;
          this.validationError = false;
          let submit = await AdsService.addNewInterestForm(form);
          console.log(submit);
          this.clear();
        } catch {
          this.showError = true;
        }
      } else {
        this.validationError = true;
        console.log("Form has validation errors");
      }
    },
    clear() {
      this.cleared = true; // Set form to cleared state
      this.$refs.form.reset(); // Reset the form
    }
  }
};
</script>

<style scoped>
.error {
  color: black;
}

.adForm {
  padding: 20px 30px;
  background-color: #fff;
}

img {
  width: 100%;
  height: auto;
  margin: 0;
}
</style>
